import Document from '@/models/Document/Document';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getDocumentDraftIcon(document: Document): string {
      if (document && !document.masterId) {
        return 'cloud_done';
      } else if (document && !document.toSend) {
        return 'cloud_queue';
      }
      return '';
    }
  }
});
