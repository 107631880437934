import findOrientation from 'exif-orientation';
import { translate } from 'exif-orientation-image';
import { ResolutionNames, Resolutions } from '../models/Resolutions';

export function getImg(file: any, resolution: ResolutionNames, cb: any) {
  findOrientation(file, function(err: any, orientation: any) {
    delete orientation.rotate;
    if (!err) {
      loadImage(URL.createObjectURL(file), function(img: any) {
        if (img) {
          resize(img, resolution, function(width: any, height: any) {
            cb(
              undefined,
              translate(img, orientation, {
                height: height,
                width: width
              })
            );
          });
        } else {
          cb(new Error('Could not load image.'));
        }
      });
    } else {
      cb(err);
    }
  });
}
function loadImage(src: any, cb: any) {
  var img = new Image();
  img.onload = function() {
    cb(img);
  };
  img.onerror = function() {
    cb();
  };
  img.src = src;
}

function resize(img: any, resolution: ResolutionNames, cb: any) {
  const max_width = (Resolutions as any).get(resolution).width;
  const max_height = (Resolutions as any).get(resolution).height;

  var width = img.width;
  var height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > max_width) {
      //height *= max_width / width;
      height = Math.round((height *= max_width / width));
      width = max_width;
    }
  } else {
    if (height > max_height) {
      //width *= max_height / height;
      width = Math.round((width *= max_height / height));
      height = max_height;
    }
  }
  cb(width, height);
}
