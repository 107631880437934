


















import Vue from 'vue';
import { getImg } from '@/helpers/imgHelper';

export default Vue.extend({
  name: 'FileInputCamera',
  data: () => ({
    isCameraActivated: false
  }),
  props: ['disabled', 'hasLabel', 'capture', 'resolution', 'quality'],
  components: {},
  computed: {
    showLabel() {
      if (this.hasLabel === '' || !!this.hasLabel) {
        return true;
      }
      return false;
    },
    cameraButtonClass(): string {
      let classes = this.isCameraActivated ? 'cameraActivated' : 'cameraDeActivated v-btn theme--light';
      classes += this.disabled ? ' v-btn--disabled' : '';
      return classes;
    },
    cameraBtnText(): string {
      return this.isCameraActivated ? 'Deactivate Camera' : 'Activate Camera';
    },
    cameraBtnIcon(): string {
      return this.isCameraActivated ? 'videocam_off' : 'videocam';
    }
  },
  methods: {
    processFile(event: any): void {
      const file = event.target.files[0];
      getImg(file, this.resolution, (err: any, canvas: HTMLCanvasElement) => {
        if (!err) {
          const image = new Image();
          image.src = canvas.toDataURL('image/jpeg', parseFloat(this.quality));

          this.$emit('handleTakePicture', image.src);
          this.$emit('handleDeActivateCamera');
        }
      });
      (this.$refs.fileInput as any).value = ''; //Allows selecting same file twice.
    }
  }
});
