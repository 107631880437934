import Document from '@/models/Document/Document';
import StoreNames from '@/models/StoreNames';
import store from '@/store/store';

const documentStoreName = StoreNames.documents;
const draftStoreName = StoreNames.documentDrafts;
const picturesStoreName = StoreNames.pictures;

export const sendDocuments = async () => {
  const documentsToSend = store.getters['documents/isToSend']();

  documentsToSend.forEach(async (document: Document) => {
    sendDocument(document);
  });

  await store.dispatch(
    'historyItems/getFromServerByStoreNames',
    [draftStoreName, documentStoreName, picturesStoreName],
    { root: true }
  );
};

export const sendDocument = async (document: Document): Promise<void> => {
  const currentDate = Date.now();
  const masterId = document.masterId;
  const draftId = document.id;

  document = {
    ...document,
    toSend: false,
    date: currentDate,
    serviceDate: document.serviceDate !== 0 ? document.serviceDate : currentDate,
    masterId: '',
    id: masterId
  };
  await store.dispatch('documents/removeById', { id: draftId, storeName: draftStoreName }, { root: true }); //draft

  await store.dispatch('documents/save', { document, storeName: documentStoreName }, { root: true }); //master
  return;
};
