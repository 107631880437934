<template>
  <v-img :src="imgSrc" v-on:error="onSrcError" aspect-ratio="1" contain ref="img">
    <template v-slot:placeholder>
      <v-layout fill-height align-center justify-center ma-0>
        <v-progress-circular indeterminate class="primary--text"></v-progress-circular>
      </v-layout>
    </template>
  </v-img>
</template>

<script>
export default {
  data: () => ({
    isFailedImg: false
  }),
  props: ['src'],
  computed: {
    imgSrc() {
      return this.isFailedImg ? `${process.env.BASE_URL}img/not-found.png` : (this.src != "data:" && this.src != "data:,")?this.src:"";
    }
  },

  methods: {
    onSrcError(event) {
      this.isFailedImg = true;
    }
  }
};
</script>

<style></style>
